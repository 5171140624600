import React from "react";
import "./JoinNow.scss";
function JoinNow() {
  return (
    <div className="join">
      <div className="join__card">
        <a className="join__link" href="http://resilientflooring.ca/pdf/RFCAO%20Contractor%20Membership%20Application.pdf" target="_blank">
          <button className="join__button">
            Apply to join as a Contractor! <br />
            <br />
            (PDF)
          </button>
        </a>
        <a className="join__link" href="http://resilientflooring.ca/pdf/RFCAO%20Associate%20Membership%20Application.pdf" target="_blank">
          <button className="join__button">
            Apply to join as an Associate!
            <br />
            <br />
            (PDF)
          </button>
        </a>
      </div>
    </div>
  );
}

export default JoinNow;
