import React, { useState } from "react";

import GALLERY_SIX_IMAGES from "../../GallerySixImages";
import PhotoPopUp from "../../components/PhotoPopUp/PhotoPopUp";

function GallerySix() {
  const gallery = GALLERY_SIX_IMAGES;
  const [activeImage, setActiveImage] = useState(null);
  const activeImageHandler = (value) => {
    setActiveImage(value);
  };
  return (
    <div className="album">
      <h2 className="album__title">RFCAO 2023 Golf Tournament</h2>
      <div className="album__blurbs">
        <p className="album__blurb">
          The Resilient Flooring Contractors Association hosted their annual
          golf tournament on July 6th at Glen Abbey Golf Club in Oakville.
        </p>
        <br></br>
        <p className="album__blurb">
          All attendees received a pair of Adidas Golf Shoes, plus one of the
          many prizes brought by golfers. They were treated to tacos and pulled
          pork sliders on course, which were delicious, followed by a buffet
          lunch and an opportunity to catch up with industry colleagues.
        </p>
        <br></br>
        <p className="album__blurb">
          Congratulations to all the winners and thanks to everyone who
          attended.
        </p>
        <br></br>
        <ul className="album__list">
          <li className="album__item">
            <b>The Winning Team:</b> Gary Chiesa, Nino DiFalco, Ross DiFalco,
            Andrew Grube
          </li>
        </ul>
        <br></br>
      </div>
      <div className="album__images">
        {GALLERY_SIX_IMAGES &&
          GALLERY_SIX_IMAGES.map((image, index) => (
            <div className="album__container" key={image.id}>
              <img
                className="album__image"
                src={image.image}
                alt={`RFCAO 2023 Golf Tournament - ${index + 1}`}
                onClick={() => {
                  setActiveImage(index);
                }}
                loading="lazy"
              />
            </div>
          ))}
      </div>
      {activeImage != null && (
        <PhotoPopUp
          gallery={gallery}
          activeImage={activeImage}
          setActiveImage={activeImageHandler}
        />
      )}
    </div>
  );
}

export default GallerySix;
