const GALLERY_SIX_IMAGES = [
  {
    id: 1,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-1.jpg",
  },
  {
    id: 2,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-2.jpg",
  },
  {
    id: 3,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-3.jpg",
  },
  {
    id: 4,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-4.jpg",
  },
  {
    id: 5,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-5.jpg",
  },
  {
    id: 6,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-6.jpg",
  },
  {
    id: 7,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-7.jpg",
  },
  {
    id: 8,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-8.jpg",
  },
  {
    id: 9,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-9.jpg",
  },
  {
    id: 10,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-10.jpg",
  },
  {
    id: 11,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-11.jpg",
  },
  {
    id: 12,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-12.jpg",
  },
  {
    id: 13,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-13.jpg",
  },
  {
    id: 14,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-14.jpg",
  },
  {
    id: 15,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-15.jpg",
  },
  {
    id: 16,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-16.jpg",
  },
  {
    id: 17,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-17.jpg",
  },
  {
    id: 18,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-18.jpg",
  },
  {
    id: 19,
    image: "images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-19.jpg",
  },
];

export default GALLERY_SIX_IMAGES;
