import React from "react";
import "./Gallery.scss";
import { Link } from "react-router-dom";
import twentyEighteen from "../../assets/images/07.webp";
import twentyNineteen from "../../assets/images/15.webp";
import twentyTwentyOne from "../../assets/images/2021.webp";
import twentyTwentyTwo from "../../assets/images/2022.webp";
import twentyTwentyThree from "../../assets/images/2023.webp";

function Gallery() {
  return (
    <div id="gallery" className="gallery">
      <Link to="/golf-2024" className="gallery__link">
        <div className="gallery__card">
          <img
            className="gallery__cover"
            src={
              "/images/PhotoGalleries/2024/2024-golf-tournament/GolfDay1.jpg"
            }
            alt="gallery cover"
            loading="lazy"
          />
          <h3 className="gallery__title">RFCAO 2024 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2023" className="gallery__link">
        <div className="gallery__card">
          <img
            className="gallery__cover"
            src={twentyTwentyThree}
            alt="gallery cover"
            loading="lazy"
          />
          <h3 className="gallery__title">RFCAO 2023 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2022" className="gallery__link">
        <div className="gallery__card">
          <img
            className="gallery__cover"
            src={twentyTwentyTwo}
            alt="gallery cover"
            loading="lazy"
          />
          <h3 className="gallery__title">RFCAO 2022 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2021" className="gallery__link">
        <div className="gallery__card">
          <img
            className="gallery__cover"
            src={twentyTwentyOne}
            alt="gallery cover"
            loading="lazy"
          />
          <h3 className="gallery__title">RFCAO 2021 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2019" className="gallery__link">
        <div className="gallery__card">
          <img
            className="gallery__cover"
            src={twentyNineteen}
            alt="gallery cover"
            loading="lazy"
          />
          <h3 className="gallery__title">RFCAO 2019 Golf Tournament</h3>
        </div>
      </Link>
      {/* 
      <Link to="/golf-2018" className="gallery__link">
        <div className="gallery__card">
          <img
            className="gallery__cover"
            src={twentyEighteen}
            alt="gallery cover"
            loading="lazy"
          />
          <h3 className="gallery__title">RFCAO 2018 Golf Tournament</h3>
        </div>
      </Link> */}
    </div>
  );
}

export default Gallery;
