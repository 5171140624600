import React from "react";
import "./BoardMembers.scss";
import data from "../../data.json";

function BoardMembers() {
  return (
    <div id="board" className="board">
      {/* <h2 className="board__">Board of Directors</h2> */}
      {data &&
        data.map((director) => {
          return (
            <div className="board__card" key={director.id}>
              <div className="board__pic">
                <img src={director.photo} alt="director" className="board__photo" loading="lazy" />
              </div>
              <div className="board__info">
                <h3 className="board__title">{director.title}</h3>
                <div className="board__info">
                  <p className="board__name">{director.name}</p>
                  <p className="board__company">{director.company}</p>
                </div>
                <p className="board__phone">{director.phone}</p>
                <a className="board__email" href={`mailto:${director.email}`}>
                  {director.email}
                </a>
                {/* <p className="board__email">{director.email}</p> */}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default BoardMembers;
