import React from "react";
import "./Footer.scss";
function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="footer__container">
        <div className="footer__section footer__section--address">
          <h5 className="footer__header">Contact Us</h5>
          <div className="footer__wraper">
            <p className="footer__text">Construction Centre, 70 Leek Crescent </p>
            <p className="footer__text">Richmond Hill, Ontario L4B 1H1</p>
          </div>
          <div className="footer__wraper">
            <p className="footer__text">Phone: 416-499-4000</p>
            <p className="footer__text">Fax: 416-499-8752</p>
          </div>
          <div className="footer__wraper">
            <a href="mailto:info@resilientflooringcontractors.ca" className="footer__text footer__text--hyperlinks">
              info@resilientflooringcontractors.ca
            </a>
          </div>
        </div>

        <div className="footer__section footer__section--links">
          <h5 className="footer__header">LINKS</h5>

          <a className="footer__text footer__text--hyperlinks" href="https://ttmgo.org/" target="_blank" rel="noreferrer">
            Terrazzo, Tile & Marble Guild of Ontario
          </a>
          <a className="footer__text footer__text--hyperlinks" href="https://www.tcaconnect.com/" target="_blank" rel="noreferrer">
            Toronto Construction Association CONNECT
          </a>
          <a className="footer__text footer__text--hyperlinks" href="https://www.supercoolcode.com/" target="_blank" rel="noreferrer">
            Site designed by Supercool Code
          </a>
        </div>
      </div>
      <div className="footer__copyright">
        <p className="footer__text">© Copyright Resilient Flooring Contractors Assication of Canada 2022</p>
      </div>
    </div>
  );
}

export default Footer;
