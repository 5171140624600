import React from "react";
import "./PhotoGalleries.scss";
import { Link } from "react-router-dom";
import twentySeventeen from "../../assets/images/01.webp";
import twentyEighteen from "../../assets/images/07.webp";
import twentyNineteen from "../../assets/images/15.webp";
import twentyTwentyOne from "../../assets/images/2021.webp";
import twentyTwentyTwo from "../../assets/images/2022.webp";
import twentyTwentyThree from "../../assets/images/2023.webp";

function PhotoGalleries() {
  return (
    <div id="galleries" className="galleries">
      <Link to="/golf-2024" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={
              "/images/PhotoGalleries/2024/2024-golf-tournament/GolfDay1.jpg"
            }
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2024 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2023" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={twentyTwentyThree}
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2023 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2022" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={twentyTwentyTwo}
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2022 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2021" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={twentyTwentyOne}
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2021 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2019" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={twentyNineteen}
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2019 Golf Tournament</h3>
        </div>
      </Link>

      <Link to="/golf-2018" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={twentyEighteen}
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2018 Golf Tournament</h3>
        </div>
      </Link>
      <Link to="/golf-2017" className="galleries__link">
        <div className="galleries__card">
          <img
            className="galleries__cover"
            src={twentySeventeen}
            alt="galleries cover"
            loading="lazy"
          />
          <h3 className="galleries__title">2017 Golf Tournament</h3>
        </div>
      </Link>
    </div>
  );
}

export default PhotoGalleries;
