import React, { useState } from "react";
import "./galleryOne.scss";
import GALLERY_ONE_IMAGES from "../../GalleryOneImages";
import PhotoPopUp from "../../components/PhotoPopUp/PhotoPopUp";

function GalleryOne() {
  const gallery = GALLERY_ONE_IMAGES;
  const [activeImage, setActiveImage] = useState(null);

  const activeImageHandler = (value) => {
    setActiveImage(value);
  };

  return (
    <div className="album">
      <h2 className="album__title">RFCAO 2017 Golf Tournament</h2>
      <div className="album__blurbs">
        <p className="album__blurb">
          The Resilient Flooring Contractors Association hosted their annual
          golf tournament at The Country Club on July 6th - a perfect day for
          golf.
        </p>
        <br></br>
        <p className="album__blurb">
          All attendees received a RFCA golf shirt and one of the numerous
          prizes brought by golfers.
        </p>
        <br></br>
        <p className="album__blurb">
          As usual Murray Clark of Centura Floor and Wall Fashions, and a RFCA
          Director, did a fantastic job as the Master of Ceremonies.
        </p>
        <br></br>
        <ul className="album__list">
          <li className="album__item">
            The Winning Team: Michael Caruso, Tina Stadnek, Kevin Holloway, Jeff
            Patterson.
          </li>
          <li className="album__item">Men's Closest To the Pin: Brad March</li>
          <li className="album__item">Men's Longest Drive: Scott Cummings</li>
          <li className="album__item">
            Ladies' Longest Drive: Isabelle Talbot
          </li>
        </ul>
        <br></br>
      </div>
      <p className="album__message">
        Congratulations to all the winners and thanks to everyone who attended!
      </p>
      <div className="album__images">
        {GALLERY_ONE_IMAGES &&
          GALLERY_ONE_IMAGES.map((image, index) => (
            <div className="album__container" key={image.id}>
              <img
                onClick={() => {
                  setActiveImage(index);
                }}
                className="album__image"
                src={image.image}
                alt={`RFCAO 2017 Golf Tournament - ${index + 1}`}
                loading="lazy"
              />
            </div>
          ))}
      </div>

      {activeImage != null && (
        <PhotoPopUp
          gallery={gallery}
          activeImage={activeImage}
          setActiveImage={activeImageHandler}
        />
      )}
    </div>
  );
}

export default GalleryOne;
