import React, { useState } from "react";
import "./galleryTwo.scss";
import GALLERY_TWO_IMAGES from "../../GalleryTwoImages";
import PhotoPopUp from "../../components/PhotoPopUp/PhotoPopUp";

function GalleryTwo() {
  const gallery = GALLERY_TWO_IMAGES;
  const [activeImage, setActiveImage] = useState(null);

  const activeImageHandler = (value) => {
    setActiveImage(value);
  };
  return (
    <div className="album">
      <h2 className="album__title">RFCAO 2018 Golf Tournament</h2>
      <div className="album__blurbs">
        <p className="album__blurb">
          The Resilient Flooring Contractors Association hosted their annual
          golf tournament at The Country Club on a hot July 5th.
        </p>
        <br></br>
        <p className="album__blurb">
          All attendees received a camping lantern and a personalized cigar
          lighter with their initials and one of the numerous prizes brought by
          golfers.
        </p>
        <br></br>
        <ul className="album__list">
          <li className="album__item">
            The Winning Team: Dean Marsh, Cody Duguid, Kevin Luck and Trevor
            McMillan
          </li>
          <li className="album__item">Men's Closest To the Pin: Brad Marsh</li>
          <li className="album__item">Men's Longest Drive: Brad Marsh</li>
          <li className="album__item">
            Ladies' Closest to the Pin: Katherine Arruda
          </li>
        </ul>
        <br></br>
      </div>
      <p className="album__message">
        Congratulations to all the winners and thanks to everyone who attended.
      </p>
      <div className="album__images">
        {GALLERY_TWO_IMAGES &&
          GALLERY_TWO_IMAGES.map((image, index) => (
            <div className="album__container" key={image.id}>
              <img
                className="album__image"
                src={image.image}
                alt={`RFCAO 2018 Golf Tournament - ${index + 1}`}
                onClick={() => {
                  setActiveImage(index);
                }}
                loading="lazy"
              />
            </div>
          ))}
      </div>
      {activeImage != null && (
        <PhotoPopUp
          gallery={gallery}
          activeImage={activeImage}
          setActiveImage={activeImageHandler}
        />
      )}
    </div>
  );
}

export default GalleryTwo;
