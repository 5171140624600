import React, { useState } from "react";
import "./MobileNavList.scss";
import { Link, useNavigate } from "react-router-dom";
import MissionStatement from "../MissionStatement/MissionStatement";
function MobileNavList({ setMissionActive, setMenuActive }) {
  let navigate = useNavigate();

  const navigateTo = async (path) => {
    const myPromise = new Promise((resolve, reject) => {
      resolve(navigate(`/#${path}`));
    });
    myPromise.then(() => {
      setTimeout(() => {
        document.getElementById(path).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 100);
    });
    setMenuActive(false);
  };

  return (
    <>
      <ul className="mobile-nav-list">
        <li className="mobile-nav-list__item">
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("hero");
              setMenuActive(false);
            }}
          >
            Home
          </a>
        </li>
        <li className="mobile-nav-list__item">
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("members");
              setMenuActive(false);
            }}
          >
            Members
          </a>
        </li>
        <li className="mobile-nav-list__item">
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("board");
              setMenuActive(false);
            }}
          >
            Board of Directors
          </a>
        </li>
        <li className="mobile-nav-list__item">
          <Link
            className="mobile-nav-list__link"
            to="/photogalleries"
            onClick={() => {
              setMenuActive(false);
            }}
          >
            Gallery
          </Link>
        </li>
        <li className="mobile-nav-list__item">
          <a
            className="mobile-nav-list__link"
            onClick={() => {
              navigateTo("footer");
              setMenuActive(false);
            }}
          >
            Links
          </a>
        </li>
        <li className="mobile-nav-list__item">
          <a
            onClick={() => {
              setMissionActive(true);
              setMenuActive(false);
            }}
            className="mobile-nav-list__link mobile-nav-list__link--bottom"
          >
            Mission Statement
          </a>
        </li>
      </ul>
    </>
  );
}

export default MobileNavList;
