import React, { useState } from "react";
import "./Members.scss";
import membersList from "../../contractors.json";
import Map from "../../components/Map/Map";

function Members() {
  const [active, setActive] = useState("contractors");
  const [searchActive, setSearchActive] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [prevCategory, setPrevCategory] = useState();

  const selectedHandler = (selected) => {
    setSelected(selected);
  };

  const activeHandler = (active) => {
    setActive(active);
  };

  const changeHandler = (e) => {
    if (active !== "search") {
      setPrevCategory(active);
    }

    if (!e.target.value) {
      setActive(prevCategory);
      setSearchActive(false);
    } else {
      setActive("search");
      setSearchActive(true);
      setSearchText(e.target.value);
    }
  };

  return (
    <div id="members" className="members">
      <div className="members__desktop-wrapper">
        <div className="members__container">
          <div className="members__container">
            <div
              className={`members__category ${active === "contractors" && "members__category--active"} `}
              onClick={() => {
                setActive("contractors");
              }}
            >
              <h4 className="members__title">CONTRACTORS</h4>
            </div>

            <div
              className={`members__category ${active === "associates" && "members__category--active"} `}
              onClick={() => {
                setActive("associates");
              }}
            >
              <h4 className="members__title">ASSOCIATES</h4>
            </div>
          </div>
          <input className={`members__desktop-search`} placeholder="Search Members" onChange={changeHandler} />
        </div>

        <div className="members__desktop-list">
          {active === "contractors" &&
            !searchActive &&
            membersList.map((member) => {
              if (member.category === "contractors") {
                return (
                  <div
                    key={member.id}
                    className="members__card"
                    onClick={() => {
                      setSelected(member);
                    }}
                  >
                    <p>{member.company}</p>
                  </div>
                );
              }
              return null;
            })}

          {active === "associates" &&
            !searchActive &&
            membersList.map((member) => {
              if (member.category === "associates") {
                return (
                  <div
                    key={member.id}
                    className="members__card"
                    onClick={() => {
                      setSelected(member);
                    }}
                  >
                    <p>{member.company}</p>
                  </div>
                );
              }
              return null;
            })}

          {searchActive &&
            membersList.map((member) => {
              if (
                member.company.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
                member.city.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
              ) {
                return (
                  <div
                    key={member.id}
                    className="members__card"
                    onClick={() => {
                      setSelected(member);
                      setActive(member.category);
                    }}
                  >
                    <p>{member.company}</p>
                  </div>
                );
              }
              return null;
            })}
        </div>

        <Map
          activeList={active}
          membersList={membersList}
          selected={selected}
          setSelected={selectedHandler}
          searchActive={searchActive}
          searchText={searchText}
          setActive={activeHandler}
        />

        <input className={`members__mobile-search`} placeholder="Search Members" onChange={changeHandler} />
      </div>

      <div className="members__mobile-list">
        {active === "contractors" &&
          !searchActive &&
          membersList.map((member) => {
            if (member.category === "contractors") {
              return (
                <div
                  key={member.id}
                  className="members__card"
                  onClick={() => {
                    setSelected(member);
                  }}
                >
                  <p>{member.company}</p>
                </div>
              );
            }
            return null;
          })}

        {active === "associates" &&
          !searchActive &&
          membersList.map((member) => {
            if (member.category === "associates") {
              return (
                <div
                  key={member.id}
                  className="members__card"
                  onClick={() => {
                    setSelected(member);
                  }}
                >
                  <p>{member.company}</p>
                </div>
              );
            }
            return null;
          })}

        {searchActive &&
          membersList.map((member) => {
            if (
              member.company.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
              member.city.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            ) {
              return (
                <div
                  key={member.id}
                  className="members__card"
                  onClick={() => {
                    setSelected(member);
                    setActive(member.category);
                  }}
                >
                  <p>{member.company}</p>
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default Members;
