import React from "react";
import Nav from "../../components/Nav/Nav";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import Members from "../../components/Members/Members";
import BoardMembers from "../../components/BoardMembers/BoardMembers";
// import PhotoGalleries from "../PhotoGalleries/PhotoGalleries";
import Gallery from "../../components/Gallery/Gallery";
import Footer from "../../components/Footer/Footer";
import FeaturedEvent from "../../components/FeaturedEvent/FeaturedEvent";

function Home() {
  return (
    <>
      <HeroBanner />
      <Members />
      <BoardMembers />
      <FeaturedEvent />
      <Gallery />
      {/* <Footer /> */}
    </>
  );
}

export default Home;
