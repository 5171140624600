const GALLERY_SEVEN_IMAGES = [
  {
    id: 1,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay1.jpg",
  },
  {
    id: 2,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay2.jpg",
  },
  {
    id: 3,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay3.jpg",
  },
  {
    id: 4,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay4.jpg",
  },
  {
    id: 5,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay5.jpg",
  },
  {
    id: 6,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay6.jpg",
  },
  {
    id: 7,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay7.jpg",
  },
  {
    id: 8,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay8.jpg",
  },
  {
    id: 9,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay9.jpg",
  },
  {
    id: 10,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay10.jpg",
  },
  {
    id: 11,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay11.jpg",
  },
  {
    id: 12,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay12.jpg",
  },
  {
    id: 13,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay13.jpg",
  },
  {
    id: 14,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay14.jpg",
  },
  {
    id: 15,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay15.jpg",
  },
  {
    id: 16,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay16.jpg",
  },
  {
    id: 17,
    image: "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay17.jpg",
  },
  {
    id: 18,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-1.jpg",
  },
  {
    id: 19,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-2.jpg",
  },
  {
    id: 20,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-3.jpg",
  },
  {
    id: 21,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-4.jpg",
  },
  {
    id: 22,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-5.jpg",
  },
  {
    id: 23,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-6.jpg",
  },
  {
    id: 24,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-7.jpg",
  },
  {
    id: 25,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-8.jpg",
  },
  {
    id: 26,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-9.jpg",
  },
  {
    id: 27,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-10.jpg",
  },
  {
    id: 28,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-11.jpg",
  },
  {
    id: 29,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-12.jpg",
  },
  {
    id: 30,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-13.jpg",
  },
  {
    id: 31,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-14.jpg",
  },
  {
    id: 32,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-15.jpg",
  },
  {
    id: 33,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-16.jpg",
  },
  {
    id: 34,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-17.jpg",
  },
  {
    id: 35,
    image:
      "images/PhotoGalleries/2024/2024-golf-tournament/GolfDay-group-18.jpg",
  },
];

export default GALLERY_SEVEN_IMAGES;
