import React, { useState } from "react";
import "./NavList.scss";
import { Link, useNavigate } from "react-router-dom";
import MissionStatement from "../MissionStatement/MissionStatement";
function NavList({ setMissionActive }) {
  let navigate = useNavigate();

  const navigateTo = async (path) => {
    const myPromise = new Promise((resolve, reject) => {
      resolve(navigate(`/#${path}`));
    });
    myPromise.then(() => {
      console.log("first");
      setTimeout(() => {
        document.getElementById(path).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }, 100);
    });
  };

  return (
    <>
      <ul className="nav-list">
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("hero");
            }}
          >
            Home
          </a>
        </li>
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("members");
            }}
          >
            Members
          </a>
        </li>
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("board");
            }}
          >
            Board of Directors
          </a>
        </li>
        <li className="nav-list__item">
          <Link className="nav-list__link" to="/photogalleries">
            Gallery
          </Link>
        </li>
        <li className="nav-list__item">
          <a
            className="nav-list__link"
            onClick={() => {
              navigateTo("footer");
            }}
          >
            Links
          </a>
        </li>
        <li className="nav-list__item">
          <a
            onClick={() => {
              setMissionActive(true);
            }}
            className="nav-list__link"
          >
            Mission Statement
          </a>
        </li>
      </ul>
    </>
  );
}

export default NavList;
