const GALLERY_ONE_IMAGES = [
  {
    id: 1,
    image: "images/PhotoGalleries/2017/2017-golf-tournament/01.webp",
  },
  {
    id: 2,
    image: "images/PhotoGalleries/2017/2017-golf-tournament/02.webp",
  },
  {
    id: 3,
    image: "images/PhotoGalleries/2017/2017-golf-tournament/03.webp",
  },
  {
    id: 4,
    image: "images/PhotoGalleries/2017/2017-golf-tournament/04.webp",
  },
  {
    id: 5,
    image: "images/PhotoGalleries/2017/2017-golf-tournament/05.webp",
  },
  {
    id: 6,
    image: "images/PhotoGalleries/2017/2017-golf-tournament/06.webp",
  },
];

export default GALLERY_ONE_IMAGES;
