import React from "react";
import "./MissionStatement.scss";
import close from "../../assets/icons/close.svg";
import infoIcon from "../../assets/icons/info_black_24dp.svg";

function MissionStatement({ setMissionActive }) {
  return (
    <div className="mission">
      <img
        src={infoIcon}
        alt="info indicator"
        className="mission__info"
        loading="lazy"
      />
      <h3 className="mission__title">Mission Statement</h3>
      <img
        className="mission__close"
        src={close}
        alt="Close Button"
        onClick={() => {
          setMissionActive(false);
        }}
        loading="lazy"
      />
      <p className="mission__text">
        The Resilient Flooring Contractors Association of Ontario (RFCAO) was
        incorporated in 1954. It is a construction industry trade association,
        the members of which are engaged in the business of supplying and
        installing flooring materials of all types including tiles, marble,
        carpet, terrazzo, etc. Members of the Assocation are involved mainly,
        but not exclusively, in the ICI and high-rise residential sectors of the
        construction industry. Companies representing the manufacturing and
        distribution of supplies and materials used in the resilient flooring
        industry are also members of RFCAO on an associate basis.
      </p>
      <p className="mission__text">
        The Association is governed by two bylaws. The first covers general
        operating procedures and the second pertains strictly to labour
        relations. Labour relations have always been a major function of the
        association through the negotiation and administration of collective
        agreements with the Carpenters International Union.
      </p>
      <p className="mission__text">
        RFCAO is the designated employer bargaining agency in the ICI sector for
        all employers in Ontario, which employ carpenters for the purposes of
        resilient flooring work. To this end, the Association negotiates as a
        part of the Carpenters EBA. The Association will also occasionally
        become involved on behalf of members in labour relations disputes
        arising out of the administration of the collective agreement.
      </p>
      <p className="mission__text">The Associations objectives are:</p>
      <ul className="mission__list">
        <li className="mission__text">
          - To promote or assist in promoting the welfare and to enhance the
          interest of the members of the corporation comprising the person,
          firms and corporations engaged in the business of resilient flooring
          and the welfare of said business generally,
        </li>
        <li className="mission__text">
          - To promote and endeavour to establish uniform trade practices and
          regulations and to eliminate trade evils and abuses,
        </li>
        <li className="mission__text">
          - To promote and/or assist in promoting and obtaining the enactment of
          legislation necessary and desirable to further or effect the objects
          herein set forth and/or calculated or intended to improve the welfare
          of those engaged in the business of resilient flooring and/or
          calculated or intended to enhance the stability of said businesses,
          and
        </li>
        <li className="mission__text">
          - To negotiate and conclude among the members of the corporation or
          between the members of the corporation and others, whether in the said
          industry or other allied activities, stability and regularity of the
          said industry.
        </li>
      </ul>
    </div>
  );
}

export default MissionStatement;
