import React from "react";
import { Link } from "react-router-dom";
import "./HeroBanner.scss";

// Images
import HeroFlooring from "../../assets/images/hero-flooring.webp";
import HeroFlooring2 from "../../assets/images/hero-flooring2.webp";
import HeroFlooring3 from "../../assets/images/hero-flooring3.webp";

// Carousel
import { Carousel } from "react-carousel-minimal";

const data = [
  {
    image: HeroFlooring,
    // caption: "2022 Ski Day",
  },
  {
    image: HeroFlooring2,
    //   // caption: "2022 Ski Day",
  },
  {
    image: HeroFlooring3,
    // caption: "2022 Ski Day",
  },
];

function HeroBanner() {
  return (
    <>
      <div className="hero">
        <div className="hero__overlay" id="hero">
          <div className="hero__text">
            <h2 className="hero__title">
              Resilient Flooring Contractors Association of Ontario
            </h2>
          </div>
          <div className="hero__buttonbox">
            <Link to={"/JoinUs"}>
              <button className="hero__join">Join Now!</button>
            </Link>
          </div>
        </div>
        <div className="hero__carousel">
          <Carousel
            data={data}
            time={4000}
            width="100%"
            // height="375px"
            minHeight="375px"
            // captionStyle={captionStyle}
            radius="0"
            slideNumber={true}
            // slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              minWidth: "320px",
              maxHeight: "600px",
              margin: "0 auto",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default HeroBanner;
