import React from "react";
import "./PhotoPopUp.scss";
import left from "../../assets/icons/left.svg";
import right from "../../assets/icons/right.svg";
import close from "../../assets/icons/close.svg";

function PhotoPopUp({ gallery, activeImage, setActiveImage }) {
  return (
    <div className="popup__overlay">
      <div className="popup__active">
        <img
          className="popup__active-image"
          src={gallery[activeImage].image}
          alt="Gallery"
          loading="lazy"
        />

        {activeImage !== 0 && (
          <img
            className="popup__left"
            src={left}
            onClick={() => {
              setActiveImage(activeImage - 1);
            }}
            alt="Back Arrow"
            loading="lazy"
          />
        )}
        {activeImage !== gallery.length - 1 && (
          <img
            className="popup__right"
            src={right}
            onClick={() => {
              setActiveImage(activeImage + 1);
            }}
            alt="Next Arrow"
            loading="lazy"
          />
        )}

        <img
          className="popup__close"
          src={close}
          alt="Close Icon"
          onClick={() => {
            setActiveImage(null);
          }}
        />
      </div>
    </div>
  );
}

export default PhotoPopUp;
