import React, { useState } from "react";
import "./Nav.scss";
import NavList from "../NavList/NavList";
import MobileNavList from "../MobileNavList/MobileNavList";
import Logo from "../../assets/logo/logo.webp";
import MenuIcon from "../../assets/icons/menu.svg";
import MissionStatement from "../MissionStatement/MissionStatement";
import { Link } from "react-router-dom";
function Nav() {
  const [menuActive, setMenuActive] = useState(false);
  const [missionActive, setMissionActive] = useState(false);

  const missionActiveHandler = (value) => {
    setMissionActive(value);
  };

  return (
    <nav className="nav">
      <div className="nav__container">
        <Link to="/">
          <img className="nav__logo" src={Logo} alt="RF Logo" />
        </Link>
        {/* Mobile Layout */}

        <div className="nav__mobile-wrapper">
          <Link to="/JoinUs">
            <button className="nav__button">Join Now!</button>
          </Link>
          <img
            className="nav__menu-icon"
            onClick={() => {
              setMenuActive(!menuActive);
            }}
            src={MenuIcon}
            alt="menu icon"
          />
        </div>

        {/* Tablet Layout */}

        <div className="nav__tablet-wrapper">
          <Link to="/JoinUs">
            <button className="nav__button nav__button-tablet">
              Join Now!
            </button>
          </Link>
          <NavList setMissionActive={missionActiveHandler} />
        </div>
      </div>

      {menuActive && (
        <MobileNavList
          setMissionActive={missionActiveHandler}
          setMenuActive={setMenuActive}
        />
      )}

      {missionActive && (
        <MissionStatement setMissionActive={missionActiveHandler} />
      )}
    </nav>
  );
}

export default Nav;
