import React, { useState } from "react";

import "./galleryFour.scss";
import GALLERY_FOUR_IMAGES from "../../GalleryFourImages";
import PhotoPopUp from "../../components/PhotoPopUp/PhotoPopUp";

function GalleryFour() {
  const gallery = GALLERY_FOUR_IMAGES;
  const [activeImage, setActiveImage] = useState(null);
  const activeImageHandler = (value) => {
    setActiveImage(value);
  };
  return (
    <div className="album">
      <h2 className="album__title">RFCAO 2021 Golf Tournament</h2>
      <div className="album__blurbs">
        <p className="album__blurb">
          The Resilient Flooring Contractors Association of Ontario held its
          golf day on July 8th, 2021 after postponing the 2020 event due to
          COVID-19. It was held at the prestigious Glen Abbey Golf Club in
          Oakville.
        </p>
        <br></br>
        <p className="album__blurb">
          Unfortunately it rained the entire day and many golfers only played a
          few holes or took a rain cheque. All attendees received an Adidas
          duffel bag containing a sleeve of balls and some Adidas apparel.
        </p>
        <br></br>
      </div>
      <div className="album__images">
        {GALLERY_FOUR_IMAGES &&
          GALLERY_FOUR_IMAGES.map((image, index) => (
            <div className="album__container" key={image.id}>
              <img
                className="album__image"
                src={image.image}
                alt={`RFCAO 2021 Golf Tournament - ${index + 1}`}
                onClick={() => {
                  setActiveImage(index);
                }}
                loading="lazy"
              />
            </div>
          ))}
      </div>
      {activeImage != null && (
        <PhotoPopUp
          gallery={gallery}
          activeImage={activeImage}
          setActiveImage={activeImageHandler}
        />
      )}
    </div>
  );
}

export default GalleryFour;
