import "./App.css";
import Home from "./pages/Home/Home";
import Nav from "./components/Nav/Nav";
import GalleryOne from "./pages/GalleryOne/GalleryOne";
import GalleryTwo from "./pages/GalleryTwo/GalleryTwo";
import GalleryThree from "./pages/GalleryThree/GalleryThree";
import GalleryFour from "./pages/GalleryFour/GalleryFour";
import PhotoGalleries from "./pages/PhotoGalleries/PhotoGalleries";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import JoinNow from "./pages/JoinNow/JoinNow";
import GalleryFive from "./pages/GalleryFive/GalleryFive";
import GallerySix from "./pages/GallerySix/GallerySix";
import GallerySeven from "./pages/GallerySeven/GallerySeven";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/photogalleries" element={<PhotoGalleries />} />
          <Route path="/golf-2017" element={<GalleryOne />} />
          <Route path="/golf-2018" element={<GalleryTwo />} />
          <Route path="/golf-2019" element={<GalleryThree />} />
          <Route path="/golf-2021" element={<GalleryFour />} />
          <Route path="/golf-2022" element={<GalleryFive />} />
          <Route path="/golf-2023" element={<GallerySix />} />
          <Route path="/golf-2024" element={<GallerySeven />} />

          <Route path="/JoinUs" element={<JoinNow />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
