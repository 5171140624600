import React, { useState } from "react";
import "./galleryThree.scss";

import GALLERY_THREE_IMAGES from "../../GalleryThreeImages";
import PhotoPopUp from "../../components/PhotoPopUp/PhotoPopUp";

function GalleryThree() {
  const gallery = GALLERY_THREE_IMAGES;
  const [activeImage, setActiveImage] = useState(null);
  const activeImageHandler = (value) => {
    setActiveImage(value);
  };
  return (
    <div className="album">
      <h2 className="album__title">RFCAO 2019 Golf Tournament</h2>
      <div className="album__blurbs">
        <p className="album__blurb">
          The Resilient Flooring Contractors Association hosted their annual
          golf tournament on July 4th at a new club this year, King's Riding
          Golf Club in King City.
        </p>
        <br></br>
        <p className="album__blurb">
          All attendees received their choice of a Nike item: shoes, a pull-over
          or a golf shirt plus one of the great prizes brought by golfers.
        </p>
        <br></br>
        <ul className="album__list">
          <li className="album__item">
            The Winning Team: Gary Chiesa, Danny Chiesa, Brad Spagnolo, Chris
            Cambareri
          </li>
          <li className="album__item">
            Men's Closest To the Pin: Jay Steinberg
          </li>
          <li className="album__item">
            Women's Closest to the Pin: Brenda Puckering
          </li>
          <li className="album__item">Men's Longest Drive: Lexus Manosa</li>
          <li className="album__item">Women's Longest Drive: Heather Werry</li>
        </ul>
        <br></br>
      </div>
      <p className="album__message">
        Congratulations to all the winners and thanks to everyone who attended.
      </p>
      <div className="album__images">
        {GALLERY_THREE_IMAGES &&
          GALLERY_THREE_IMAGES.map((image, index) => (
            <div className="album__container" key={image.id}>
              <img
                className="album__image"
                src={image.image}
                alt={`RFCAO 2019 Golf Tournament - ${index + 1}`}
                onClick={() => {
                  setActiveImage(index);
                }}
                loading="lazy"
              />
            </div>
          ))}
      </div>
      {activeImage != null && (
        <PhotoPopUp
          gallery={gallery}
          activeImage={activeImage}
          setActiveImage={activeImageHandler}
        />
      )}
    </div>
  );
}

export default GalleryThree;
