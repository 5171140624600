import React, { useState } from "react";
import GALLERY_FIVE_IMAGES from "../../GalleryFiveImages";
import PhotoPopUp from "../../components/PhotoPopUp/PhotoPopUp";

function GalleryFive() {
  const gallery = GALLERY_FIVE_IMAGES;
  const [activeImage, setActiveImage] = useState(null);
  const activeImageHandler = (value) => {
    setActiveImage(value);
  };
  return (
    <div className="album">
      <h2 className="album__title">RFCAO 2022 Golf Tournament</h2>
      <div className="album__blurbs">
        <p className="album__blurb">
          The Resilient Flooring Contractors Association held its first shotgun
          golf tournament in 3 years since Covid started and it was an amazing
          day at Glen Abbey Golf Club in Oakville.
        </p>
        <br></br>
        <p className="album__blurb">
          All attendees received a Yeti rambler and 2 sleeves of golf balls plus
          one of the many prizes brought by golfers. They were treated to
          mojitos and pulled pork sliders on course, which were delicious,
          followed by a buffet lunch and an opportunity to catch up with
          industry colleagues who they had not seen in a while.
        </p>
        <br></br>
        <p className="album__blurb">Congratulations to all the winners:</p>
        <br></br>
        <ul className="album__list">
          <li className="album__item">
            The Winning Team: Derek Kent, Chris Johnson, Silvano Carlini, Jason
            Madore
          </li>
          <li className="album__item">Men’s Closest to the Pin: Sean Palmer</li>
          <li className="album__item">Men’s Longest Drive: Vache Boghos</li>
          <li className="album__item">Women’s Longest Drive: Gina Ramos</li>
        </ul>
        <br></br>
      </div>
      <div className="album__images">
        {GALLERY_FIVE_IMAGES &&
          GALLERY_FIVE_IMAGES.map((image, index) => (
            <div className="album__container" key={image.id}>
              <img
                className="album__image"
                src={image.image}
                alt={`RFCAO 2022 Golf Tournament - ${index + 1}`}
                onClick={() => {
                  setActiveImage(index);
                }}
                loading="lazy"
              />
            </div>
          ))}
      </div>
      {activeImage != null && (
        <PhotoPopUp
          gallery={gallery}
          activeImage={activeImage}
          setActiveImage={activeImageHandler}
        />
      )}
    </div>
  );
}

export default GalleryFive;
