const GALLERY_FIVE_IMAGES = [
  {
    id: 1,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-01.webp",
  },
  {
    id: 2,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-02.webp",
  },
  {
    id: 3,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-03.webp",
  },
  {
    id: 4,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-04.webp",
  },
  {
    id: 5,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-05.webp",
  },
  {
    id: 6,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-06.webp",
  },
  {
    id: 7,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-07.webp",
  },
  {
    id: 8,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-08.webp",
  },
  {
    id: 9,
    image:
      "images/PhotoGalleries/2022/2022-golf-tournament/2022-golf-tournament-09.webp",
  },
];

export default GALLERY_FIVE_IMAGES;
