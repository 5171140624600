const GALLERY_THREE_IMAGES = [
  {
    id: 1,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-01.webp",
  },
  {
    id: 2,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-02.webp",
  },
  {
    id: 3,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-03.webp",
  },
  {
    id: 4,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-04.webp",
  },
  {
    id: 5,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-05.webp",
  },
  {
    id: 6,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-06.webp",
  },
  {
    id: 7,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-07.webp",
  },
  {
    id: 8,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-08.webp",
  },
  {
    id: 9,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-09.webp",
  },
  {
    id: 10,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-10.webp",
  },
  {
    id: 11,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-11.webp",
  },
  {
    id: 12,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-12.webp",
  },
  {
    id: 13,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-13.webp",
  },
  {
    id: 14,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-14.webp",
  },
  {
    id: 15,
    image:
      "images/PhotoGalleries/2019/2019-golf-tournament/2019-golf-tournament-15.webp",
  },
];

export default GALLERY_THREE_IMAGES;
